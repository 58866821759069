import { clearEmployeeJwtCookie, setEmployeeJwtCookie } from '@gffinance/groundfloor-ui';
import { MutationTree } from 'vuex';

import { PublishableProgressUpdate, Employee } from '@/api/queries/generatedTypes';
import { RootState } from '@/types/general.types';

const mutations: MutationTree<RootState> = {
	setInitPromise(state, initPromise: Promise<void> | null): void {
		state.initPromise = initPromise;
	},
	setToken(state, token: string | null): void {
		if (token) {
			setEmployeeJwtCookie(token);
		} else {
			clearEmployeeJwtCookie();
		}

		state.token = token;
	},
	setEmployee(state, employee: Employee | null): void {
		state.employee = employee;
	},
	setConsumerToken: (state, token: string) => (state.consumerToken = token),
	setIsLoadingUpdates: (state, isLoadingUpdates: boolean) => (state.isLoadingUpdates = isLoadingUpdates),
	setUnfilteredProgressUpdates: (state, unfilteredProgressUpdates: PublishableProgressUpdate[]) => (state.unfilteredProgressUpdates = unfilteredProgressUpdates),
};

export default mutations;
