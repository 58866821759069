import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { defineComponent, reactive } from 'vue';

import { namedRoutes } from '@/router/router';
import { LoginArguments } from '@/types/general.types';

enum AUTH_SCOPES {
	READ = 'read',
	WRITE = 'write',
}

export default defineComponent({
	name: 'Login',
	setup() {
		const state = reactive({
			email: '',
			password: '',
		});
		const rules = {
			email: { required, email },
			password: { required },
		};
		const v$ = useVuelidate(rules, state);
		return { state, v$ };
	},
	data() {
		return {
			submitted: false,
		};
	},
	computed: {
		emailType(): string {
			return (this.submitted && this.v$.email.$invalid) ? 'danger' : '';
		},
		emailMessage(): string {
			if (this.submitted && this.v$.email.required.$invalid) {
				return 'Email address is required';
			} else if (this.submitted && this.v$.email.email.$invalid) {
				return 'Please enter a valid email address';
			} else {
				return '';
			}
		},
		passwordType(): string {
			return this.submitted && this.v$.password.$invalid ? 'danger' : '';
		},
		passwordMessage(): string {
			return this.submitted && this.v$.password.$invalid ? 'Password is required' : '';
		},
	},
	methods: {
		handleSubmit() {
			this.submitted = true;

			if (!this.v$.$invalid) {
				const args: LoginArguments = {
					apollo: this.$apollo.getClient(),
					credentials: {
						email: this.state.email,
						password: this.state.password,
						scopes: [
							AUTH_SCOPES.READ,
							AUTH_SCOPES.WRITE,
						],
					},
				};

				this.$store.dispatch('login', args)
					.then(() => {
						this.$router.push({ name: namedRoutes.Dashboard.name });
					})
					.catch((e) => {
						console.error(e);
						this.$buefy.toast.open({
							message: e.message,
							type: 'is-danger',
						});
					});
			} else {
				this.$buefy.toast.open({
					message: 'Thar she blows! Invalid form ahoy!',
					type: 'is-danger',
				});
			}
		},
	},
});
