import Fuse from 'fuse.js';
import { DateTime } from 'luxon';
import Vue from 'vue';
import { VueEditor } from 'vue2-editor';

import { ARCHIVE_PROGRESS_UPDATE } from '../../api/queries/archive-progress-update';
import {
	ArchiveProgressUpdateMutation,
	ArchiveProgressUpdateMutationVariables,
	PublishableProgressUpdate,
} from '../../api/queries/generatedTypes';

import ArchiveProgressUpdate from '@/components/archive-progress-update/archive-progress-update.component.vue';
import { namedRoutes } from '@/router/router';
import { formatCents } from '@/utils';

export default Vue.extend({
	name: 'ListProgressUpdates',
	components: {
		VueEditor,
	},
	data() {
		return {
			searchText: '',
			isArchiving: false,
			currentlyArchiving: null as PublishableProgressUpdate | null,
			perPage: 12,
			currentPage: 1,
			filterOptions: {
				includeScore: true,
				includeMatches: true,
				distance: 100,
				threshold: 0.1,
				ignoreLocation: true,
				keys: ['html', 'principalLastName', 'principalFirstName', 'loanName'],
			},
		};
	},
	computed: {
		isPaginated(): boolean {
			return this.$store.getters.progressUpdates.length > this.perPage;
		},
		showLoading(): boolean {
			return this.$store.state.isLoadingUpdates;
		},
		showNoResults(): boolean {
			return !this.$store.state.isLoadingUpdates && !this.$store.getters.progressUpdates.length;
		},
		showResults(): boolean {
			return !this.$store.state.isLoadingUpdates && this.$store.getters.progressUpdates.length > 0;
		},
		isSearching(): boolean {
			return this.searchText.length > 1;
		},
		unfilteredProgressUpdates(): PublishableProgressUpdate[] {
			return this.$store.getters.progressUpdates;
		},
		filteredProgressUpdates(): PublishableProgressUpdate[] {
			const searchResults = new Fuse(this.unfilteredProgressUpdates, this.filterOptions);
			const searchedProgressUpdates = this.isSearching ? searchResults.search(this.searchText) : [];

			return searchedProgressUpdates.map((searchedProgressUpdate) => searchedProgressUpdate.item);
		},
		progressUpdates(): PublishableProgressUpdate[] {
			return this.isSearching ? this.filteredProgressUpdates : this.unfilteredProgressUpdates;
		},
	},
	mounted() {
		// Dispatching here ensure that we refresh the store each time the user navigates to this view, particularly when navigating back from the detail view.
		// This dispatch is duplicated in the progress updates view intentionally.
		this.$store.dispatch('loadProgressUpdates', this.$apollo.getClient()).catch((e) => {
			console.error(e);
			this.$buefy.toast.open({
				message: e.message,
				type: 'is-danger',
			});
		});
	},
	methods: {
		formatCents,
		formatDate(update: PublishableProgressUpdate): string {
			return update.lastUpdatedAt ? DateTime.fromISO(update.lastUpdatedAt).toLocaleString() : '';
		},
		handleArchive(progressUpdate: PublishableProgressUpdate): void {
			this.$buefy.modal.open({
				component: ArchiveProgressUpdate,
				hasModalCard: true,
				props: {
					progressUpdate,
				},
				events: {
					confirm: () => {
						this.handleArchiveConfirm(progressUpdate);
					},
				},
			});
		},
		async handleArchiveConfirm(progressUpdate: PublishableProgressUpdate) {
			try {
				this.currentlyArchiving = progressUpdate;
				console.log('progressUpdates', this.currentlyArchiving);
				await this.$apollo.mutate<ArchiveProgressUpdateMutation, ArchiveProgressUpdateMutationVariables>({
					mutation: ARCHIVE_PROGRESS_UPDATE,
					variables: { progressUpdateId: progressUpdate.id },
				});

				this.$buefy.toast.open({
					message: 'Progress update archived',
					type: 'is-success',
				});

				this.$store.dispatch('loadProgressUpdates', this.$apollo.getClient()).catch((e) => {
					console.error(e);
					this.$buefy.toast.open({
						message: e.message,
						type: 'is-danger',
					});
				});
			} catch (e) {
				console.error(e);
				this.$buefy.toast.open({
					message: 'Failed to archive progress update',
					type: 'is-danger',
				});
			} finally {
				this.currentlyArchiving = null;
			}
		},
		isBeingArchived(update: PublishableProgressUpdate): boolean {
			return this.currentlyArchiving !== null && this.currentlyArchiving.id === update.id;
		},
		getPublishUpdateTo(update: PublishableProgressUpdate) {
			return {
				name: namedRoutes.PublishProgressUpdate.name,
				params: {
					progressUpdateId: update.id,
				},
			};
		},
	},
});
