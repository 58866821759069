import { ApolloClient, gql } from '@apollo/client/core';

import { IssueEmployeeJwtInput, IssueEmployeeJwtMutation, IssueEmployeeJwtMutationVariables } from './generatedTypes';

export const ISSUE_EMPLOYEE_JWT = gql`
	mutation IssueEmployeeJwt($input: IssueEmployeeJwtInput!) {
		issueEmployeeJwt(input: $input) {
			jwtPayload: jwt {
				jwt
				tokenType
			}
			errors {
				code
				detail
				status
			}
		}
	}
`;

export const getEmployeeTokenByAuthCredentials = async (
	apollo: ApolloClient<unknown>,
	credentials: IssueEmployeeJwtInput['credentials'],
) => {
	const { data } = await apollo.mutate<IssueEmployeeJwtMutation, IssueEmployeeJwtMutationVariables>({
		mutation: ISSUE_EMPLOYEE_JWT,
		variables: {
			input: { credentials },
		},
	});

	return data?.issueEmployeeJwt?.jwtPayload?.jwt;
};
