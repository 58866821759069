import { gql } from '@apollo/client/core';

export const ARCHIVE_PROGRESS_UPDATE = gql`
	mutation ArchiveProgressUpdate($progressUpdateId: ID!) {
		archiveProgressUpdate(input: { progressUpdateId: $progressUpdateId }) {
			errors {
				status
				detail
				code
			}
		}
	}
`;
