
import Vue, { PropOptions } from 'vue';

import { PublishableProgressUpdate } from '@/api/queries/generatedTypes';
import { getAaUrl } from '@/utils';

export default Vue.extend({
	name: 'ArchiveProgressUpdate',
	props: {
		progressUpdate: {
			type: Object,
			required: true,
		} as PropOptions<PublishableProgressUpdate>,
	},
	methods: {
		handleArchive(): void {
			this.$emit('confirm');
			this.$emit('close');
		},
		getAaUrl,
	},
});
