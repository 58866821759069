import { GetterTree } from 'vuex';

import { ProgressUpdateState, EmployeeRoleName } from '@/api/queries/generatedTypes';
import { RootState } from '@/types/general.types';

const getters: GetterTree<RootState, RootState> = {
	progressUpdates: (state) =>
		state.unfilteredProgressUpdates.filter((p) => p.state === ProgressUpdateState.Unpublished),
	isSuperAdmin(state) {
		return state.employee === null
			? false
			: state.employee.employeeRoles.some((employeeRole) => employeeRole.name === EmployeeRoleName.SuperAdmin);
	},
	isPressManager(state) {
		return state.employee === null
			? false
			: state.employee.employeeRoles.some((employeeRole) => employeeRole.name === EmployeeRoleName.PressManager);
	},
	isMarketingManager(state) {
		return state.employee === null
			? false
			: state.employee.employeeRoles.some((employeeRole) => employeeRole.name === EmployeeRoleName.MarketingManager);
	},
	isFinanceOfficer(state) {
		return state.employee === null
			? false
			: state.employee.employeeRoles.some((employeeRole) => employeeRole.name === EmployeeRoleName.FinanceOfficer);
	},
	isLoanOfficer(state) {
		return state.employee === null
			? false
			: state.employee.employeeRoles.some((employeeRole) => employeeRole.name === EmployeeRoleName.LoanOfficer);
	},
	isPromotionalCreditApprover(state) {
		return state.employee === null
			? false
			: state.employee.employeeRoles.some(
					(employeeRole) => employeeRole.name === EmployeeRoleName.PromotionalCreditApprover,
			  );
	},
	canDoPublisher(state, getters) {
		return (
			getters.isSuperAdmin || getters.isLoanOfficer || getters.isFinanceOfficer || getters.isPromotionalCreditApprover
		);
	},
};

export default getters;
