import { gql } from '@apollo/client/core';

export const FETCH_LENDING_LOAN = gql`
	query LendingLoan($loanId: ID!) {
		lendingLoan(id: $loanId) {
			... on LendingLoan {
				id
				name
				amountCents
				term
				rate
				rating
				repaymentPosition
				constructionSecondaryDrawAmountCents
				loanToValueScore
				qualityOfValuationReportScore
				locationScore
				borrowerCommitmentScore
				borrowerExperienceScore
				skinInTheGameScore
				projectedMaturityDate
				projectedRepaymentOn
				calculatedPortfolioAmountCents
				propertyPhotos {
					id
					url
					fileType
				}
				address {
					id
					address1
					address2
					city
					state
					zipcode
					country
					countryIsoCode
					latitude
					longitude
				}
				lendingLoanApplication {
					id
					term
					purpose
					paymentType
					securityPosition
					propertyType
					totalRehabBudgetCents
					afterRepairValueCents
					afterRepairValueBasedOn
					propertyValueCents
					originalPurchaseDate
					keyLoanFeature
					lendingPrincipal {
						id
						firstName
						lastName
						principalStrategicFocus
						fundedOrRepaidLoansCount
						repaidLoansCount
						repaymentPercentage
						annuallyCompletedProjectsAverageCount
						averageProjectSalesPriceCents
						averageProjectsDurationMonths
						averageProjectCostCents
					}
					lendingBorrowerEntity {
						id
						fullName
						dateOfFormation
						totalInventoryValueCents
						totalDebtCents
						unsoldUnitsCount
						agedUnsoldUnitsCount
						completedProjectsCount
						revenueCents
						grossMarginPercentage
					}
					coverImage {
						id
						url
						fileType
					}
				}
				publishedLoans {
					id
					amountCents
					portfolioAmountCents
					amountInvestedCents
					amountMaxInvestmentCents
					amountRemainingCents
					borrowerExperienceScore
					cap
					descriptiveTitle
					externalCloseDate
					investorsCount
					loanMaturesOn
					loanToValueScore
					loanValuationPercent
					locationScore
					#marketingCategory
					name
					paymentType
					purpose
					qualityOfValuationReportScore
					rate
					rating
					securityMaturesAt
					#securityPurchaseConfiguration
					skinInTheGameScore
					slug
					state
					summary
					term
					valuationAbbrev
					valuationLabel
					valuationValue
					address {
						id
						address1
						address2
						city
						state
						zipcode
						country
						countryIsoCode
						latitude
						longitude
					}
				}
			}
		}
	}
`;
