import { ApolloClient, gql } from '@apollo/client/core';

import { IssueApiConsumerJwtMutation, IssueApiConsumerJwtMutationVariables } from './generatedTypes';

export const ISSUE_API_CONSUMER_JWT = gql`
	mutation IssueApiConsumerJwt {
		issueApiConsumerJwt(input: {}) {
			jwtPayload: jwt {
				jwt
				tokenType
			}
			errors {
				status
				detail
				code
			}
		}
	}
`;

export const getConsumerToken = async (apollo: ApolloClient<unknown>) => {
	const { data } = await apollo.mutate<IssueApiConsumerJwtMutation, IssueApiConsumerJwtMutationVariables>({
		mutation: ISSUE_API_CONSUMER_JWT,
	});

	return data?.issueApiConsumerJwt?.jwtPayload?.jwt;
};
