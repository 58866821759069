import { ApolloClient, gql } from '@apollo/client/core';

import { PublishableProgressUpdatesQuery, PublishableProgressUpdatesQueryVariables } from './generatedTypes';

export const FETCH_PUBLISHABLE_PROGRESS_UPDATES = gql`
	query PublishableProgressUpdates {
		publishableProgressUpdates {
			id
			html
			lastUpdatedAt
			state
			principalFirstName
			principalLastName
		}
	}
`;

export const fetchPublishableProgressUpdates = async (apollo: ApolloClient<unknown>) => {
	const { data } = await apollo.query<PublishableProgressUpdatesQuery, PublishableProgressUpdatesQueryVariables>({
		query: FETCH_PUBLISHABLE_PROGRESS_UPDATES,
	});
	return data.publishableProgressUpdates;
};
