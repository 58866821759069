import Dinero from 'dinero.js';
import { CustomRule } from 'vuelidate/lib/validators';

import { LendingLoanQuery } from './api/queries/generatedTypes';

import { ACCOUNT_TYPES } from '@/constants';

export const greaterThanZeroValidator: CustomRule = (value) => Number(value) > 0;

export function formatCents(cents: number): string {
	return Dinero({
		amount: cents,
		currency: 'USD',
	}).toFormat();
}

export function getAaUrl(loanId: string): string {
	return `https://${process.env.VUE_APP_RAILS_DOMAIN}/admin/loans/${loanId}`;
}

export function capitalizeString(value: string): string {
	return value[0].toUpperCase() + value.slice(1, value.length);
}

export function getPrettyAccountType(type: string): string {
	const map = {
		[ACCOUNT_TYPES.INVESTOR]: 'Investor',
		[ACCOUNT_TYPES.INVESTOR_AUTO]: 'Auto-Investor',
		[ACCOUNT_TYPES.INVESTOR_IRA]: 'IRA',
		[ACCOUNT_TYPES.INVESTOR_YIELD]: 'Stairs',
	};

	return map[type];
}
type LendingLoan = LendingLoanQuery['lendingLoan'];
type LendingLoanApplication = LendingLoan['lendingLoanApplication'];
type LendingPrincipal = LendingLoanApplication['lendingPrincipal'];
type LendingBorrowerEntity = LendingLoanApplication['lendingBorrowerEntity'];

export function getBorrowerEntityFromLendingBorrowerEntity(lendingBorrowerEntity: LendingBorrowerEntity) {
	// Remove and rename keys
	const {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		__typename,
		id,
		unsoldUnitsCount: unsoldUnits,
		agedUnsoldUnitsCount: agedUnsoldUnit,
		grossMarginPercentage: grossMargin,
		completedProjectsCount: projectsCompleted,
		grossMarginPercentage,
		...otherKeys
	} = lendingBorrowerEntity || {};

	return {
		...otherKeys,
		unsoldUnits,
		agedUnsoldUnit,
		projectsCompleted,
		grossMargin: Number(grossMargin),
	};
}

export function getLoanInputFromLendingLoan(lendingLoan: LendingLoan) {
	// Remove and rename keys
	const {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		__typename,
		id,
		lendingLoanApplication,
		address,
		term,
		propertyPhotos,
		borrowerCommitmentScore,
		skinInTheGameScore,
		rating,
		publishedLoans,
		projectedMaturityDate: loanMaturesOn,
		calculatedPortfolioAmountCents: portfolioAmountCents,
		...otherKeys
	} = lendingLoan;

	return {
		...otherKeys,
		skinInTheGameScore,
		borrowerCommitmentScore,
		portfolioAmountCents,
		ratingsScore: Number(rating),
		rating,
		loanMaturesOn,
		term,
	};
}
export function getPrincipalFromLendingPrincipal(lendingPrincipal: LendingPrincipal) {
	// Remove and rename keys
	const {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		__typename,
		id,
		fundedOrRepaidLoansCount,
		averageProjectCostCents,
		annuallyCompletedProjectsAverageCount,
		...otherKeys
	} = lendingPrincipal || {};

	return {
		...otherKeys,
		fundedLoansCount: fundedOrRepaidLoansCount,
		averageProjectCostCents: averageProjectCostCents || 0,
		annuallyCompletedProjectsAverageCount: annuallyCompletedProjectsAverageCount || 0,
	};
}

export function getLoanApplicationFromLendingLoanApplication(lendingLoanApplication: LendingLoanApplication) {
	// Remove and rename keys
	const {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		__typename,
		id,
		term,
		lendingPrincipal,
		lendingBorrowerEntity,
		coverImage,
		...otherKeys
	} = lendingLoanApplication;

	return {
		...otherKeys,
		term,
	};
}

export function getLendingDataConversionInputFromLendingLoan(lendingLoan: LendingLoan) {
	// Remove and rename keys
	const { address, lendingLoanApplication } = lendingLoan;

	const { lendingPrincipal, lendingBorrowerEntity } = lendingLoanApplication;

	const {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		__typename,
		id,
		...trimmedAddress
	} = address;

	return {
		loan: getLoanInputFromLendingLoan(lendingLoan),
		address: {
			id: undefined,
			...trimmedAddress,
		},
		lendingLoanApplicationId: Number(lendingLoanApplication.id),
		loanApplication: getLoanApplicationFromLendingLoanApplication(lendingLoanApplication),
		principal: getPrincipalFromLendingPrincipal(lendingPrincipal),
		borrowerEntity: getBorrowerEntityFromLendingBorrowerEntity(lendingBorrowerEntity),
		imageId: undefined,
	};
}
