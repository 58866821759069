import { ApolloClient, gql } from '@apollo/client/core';

import { FetchEmployeeByClientQuery, FetchEmployeeByClientQueryVariables } from './generatedTypes';

export const FETCH_EMPLOYEE_BY_CLIENT = gql`
	query FetchEmployeeByClient {
		fetchEmployeeByClient {
			email
			employeeRoles {
				id
				name
				state
			}
			id
		}
	}
`;

export const fetchEmployeeByClient = async (apollo: ApolloClient<unknown>) => {
	const { data } = await apollo.query<FetchEmployeeByClientQuery, FetchEmployeeByClientQueryVariables>({
		query: FETCH_EMPLOYEE_BY_CLIENT,
	});
	return data.fetchEmployeeByClient;
};
