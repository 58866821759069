import { DefaultApolloClient } from '@vue/apollo-composable';
import Vue, { provide } from 'vue';

import apolloProvider from '@/apollo-client';
import { DEFAULT_PAGE_TITLE } from '@/constants';

export default Vue.extend({
	name: 'App',
	setup() {
		provide(DefaultApolloClient, apolloProvider.defaultClient);
	},
	mounted() {
		this.$store.dispatch('init', this.$apollo.getClient()).catch((e) => {
			console.error('Error loading profile!', e);
			this.$buefy.toast.open({
				message: e.message,
				type: 'is-danger',
			});
		});
	},
	metaInfo: {
		title: DEFAULT_PAGE_TITLE,
	},
});
