import { gql } from '@apollo/client/core';

export const CREATE_INVESTMENT_OPPORTUNITY_FROM_LENDING_DATA_QUERY = gql`
	mutation CreateInvestmentOpportunityFromLendingData($input: CreateInvestmentOpportunityFromLendingDataInput!) {
		createInvestmentOpportunityFromLendingData(input: $input) {
			loanApplication {
				id

				principal {
					id
				}

				borrowerEntity {
					id
				}
			}

			errors {
				status
				detail
				code
			}
		}
	}
`;
